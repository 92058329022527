import { useEffect, useRef } from 'react';

interface PageLayoutProps {
  children: React.ReactNode;
}

const PageLayout: React.FC<PageLayoutProps> = ({ children }) => {
  const ref = useRef<HTMLDivElement>(null);
  const appBarPadding = 16;
  const notchHeight = MainUI.getNotchValue().statusHeight;
  let statusHeight = notchHeight ? notchHeight + appBarPadding : 0;

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    if (BResource.getSelectedLocale() === 'ar') {
      ref.current.setAttribute('dir', 'rtl');
    }

    ref.current.style.height = `calc(100vh - ${statusHeight}px)`;
    ref.current.style.top = `${statusHeight}px`;
  }, [statusHeight]);

  return (
    <div ref={ref} className={`absolute block w-screen h-screen bg-gray-100`}>
      <div style={{ top: `${-statusHeight}px`, height: `${statusHeight}px` }} className={`w-screen bg-gray-100 absolute`}></div>
      {children}
    </div>
  );
};

export default PageLayout;
