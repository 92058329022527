interface BodyProps {
  children: React.ReactNode;
  className?: string;
}

const Body: React.FC<BodyProps> = ({ children, className='' }) => {
  return <div 
    className={`block absolute w-full h-[calc(100%-56px)] left-0 top-[56px] p-0 z-[5] overflow-y-scroll bg-white text-black ${className}`}>
      {children}
    </div>;
};

export default Body;
