import { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import useScrollRestoration from './hooks/common/useScrollRestoration';
import Home from './pages/Home';
import Dialog from './pages/examples/Dialog';
import Examples from './pages/examples/Examples';
import Lovelink from './pages/examples/Lovelink';
import PageAbout from './pages/settings/PageAbout';
import PageRoute from './pages/examples/PageRoute';
import Purchase from './pages/examples/Purchase';
import ScrollRestoration from './pages/examples/ScrollRestoration';
import PagePrivacySetting from './pages/settings/PagePrivacySetting';
import ButtonExamples from 'components/examples/Buttons';

/**
 * 이곳에 페이지 정보를 추가하면, React router와 Wepapp Transition 모듈에 등록된다.
 */
const pages = [
  { path: '/', element: <Home /> },
  { path: '/examples', element: <Examples /> },
  { path: '/examples/page-route', element: <PageRoute /> },
  { path: '/examples/scroll-restoration', element: <ScrollRestoration /> },
  { path: '/examples/dialog', element: <Dialog /> },
  { path: '/examples/purchase', element: <Purchase /> },
  { path: '/examples/lovelink', element: <Lovelink /> },
  { path: '/examples/buttons', element: <ButtonExamples /> },
  { path: Def.PAGE_ABOUT, element: <PageAbout /> },
  { path: Def.PAGE_PRIVACYSETTINGS, element: <PagePrivacySetting /> },
];

let inited = false;

function App() {
  const navigate = useNavigate();
  const { store } = useScrollRestoration();

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * App 초기화에 필요한 작업을 수행한다.
   */
  function init() {
    if (inited) {
      return;
    }
    inited = true;

    if (process.env.REACT_APP_EXAMPLES === 'include') {
      const elem = document.querySelector('#hReactExample');
      if (elem) {
        const targetElem = elem.parentElement?.parentElement;
        if (targetElem) {
          targetElem.style.display = 'flex';
        }
      }
    }

    // React 페이지를 Webapp Transition 모듈에 등록.
    pages.forEach((page) => {
      if (process.env.REACT_APP_EXAMPLES !== 'include' && page.path.startsWith('/examples')) {
        return;
      }

      Transition.registerPage(page.path, {
        show: (data: any, type: 'push' | 'pop') => {
          if (type === 'push') {
            navigate(page.path);
          } else {
            navigate(-1);
          }
        },
        hide: (type: 'hide' | 'dispose') => {
          if (type === 'hide') {
            store(page.path);
            navigate('/');
          } else {
            navigate(-1);
          }
        },
      });
    });
  }

  return (
    <Routes>
      {pages.map((page, index) => (
        <Route key={index} path={page.path} element={page.element} />
      ))}
    </Routes>
  );
}

export default App;
